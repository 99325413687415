.dropdown-menu {
    background: rgb(59, 73, 144);
    width: 100px;
    position: absolute;
    top: 70px;
    list-style: none;
    text-align: start;
    display: block;
  }
  
  .dropdown-menu li {
    background: #1888ff;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: #5cabff;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 8px;
  }
  
  @media screen and (max-width: 600px) {
    .fa-caret-down {
      display: none;
    }
  }