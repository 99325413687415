.projetsLink {
    text-decoration: none;
    padding: 0 7px;
    font-size: 21px;
    color :rgb(59, 73, 144);
}
.projetsLink:hover {
    color: red;
    font-size: 25px;
}
.realItemContinue {
    position: relative;
    top: 18px;
    left: 20px;
    font-size: 12px;
    width: 36%;
    background-color: rgb(59, 73, 144);
    color: white !important;
    padding: 3px 9px;
    border: none;
    border-radius: 20px;
}
.realItemPlaning {
    position: relative;
    top: 18px;
    left: 20px;
    font-size: 12px;
    width: 36%;
    background-color: rgb(0, 255, 0);
    color: white !important;
    padding: 3px 9px;
    border: none;
    border-radius: 20px;
}

.realItemFinis {
    position: relative;
    top: 18px;
    left: 20px;
    font-size: 12px;
    width: 36%;
    background-color:gray;
    color: white !important;
    padding: 3px 9px;
    border: none;
    border-radius: 20px;
}
.cardGeneral {
    -webkit-box-shadow: 6px 5px 13px 10px rgba(0,0,0,0.69); 
    box-shadow: 6px 5px 13px 10px rgba(0,0,0,0.69);
    color: rgb(59, 73, 144) !important;
}
h5{
    color: rgb(59, 73, 144) !important;
}
.cardAllContent {
    color: rgb(59, 73, 144) !important;
}

@media screen and (max-width: 600px) {
    .realItemContinue,.realItemPlaning,.realItemFinis {
        width: 47%;
        font-size: 10px;
    }
    .cardContentInfo {
        font-size: 10px;
    }
    .cardAllGeneral h5{
        font-size: 20px !important;
    }
    .cardAllContent{
        font-size: 10px !important;
    }
}

