.homeMain,.cardEmmlakMain {
    color: rgb(59, 73, 144);
}

@media screen and (max-width: 600px) {
    .homeMain-h4 {
        font-size: 25px;
    }
    .cardEmmlakMain {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .cardEmmlakMain h4 {
        font-size: 20px !important;
    }
    .cardEmmlakGrid {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        margin-left: 2px !important;
    }
}