

@media screen and (max-width: 600px) {
    .missionMain h4{
        font-size: 12px !important;
        font-weight: bolder;
    }
    .missionMain h5{
        font-size: 10px !important;
        font-weight: bold;
    }
    .missionMain p{
        font-size: 8px !important;
        font-weight: lighter;
    }
}