.advertProject {
    width: 100%;
    height: 200px;
    background-color: rgb(59, 73, 144);
    margin-top: 97px;
    color: white;
    display: flex;
}
.advertGenelWriting {
    padding: 56px 94px;
    color: white;
}

.advertProjectPhoto {
    padding: 10px 18px;
    height: 200px;
    width: auto;
    border: none;
}
.advertProjectPhoto p {
    position: absolute;
    margin: 10px 10px;
    padding: 6px 10px;
    background-color: white;
    border-radius: 20px;
    color: rgb(59, 73, 144);
    font-size: 12px;
    font-weight: 900;
}

.advertProjectImg {
    width: auto;
    height: 100%;
    border: none;
    border-radius: 20px;
}

@media screen and (max-width: 600px) {
    .advertProject {
        margin-top: 82px;
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .advertGenelWriting {
        padding: 10px 24px;

    }
    .advertGenelWriting h1{
        font-size: 25px;
    }
    .advertGenelWriting h5{
        font-size: 15px;
    }
    .advertProjectPhoto p {
        font-size: 10px;
        font-weight: 700;
    }
}