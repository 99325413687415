* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

.svgyeubMain {
    width: 100%;
    height: 100%;
}

#svg14 {
    background-color: red !important;
}

.alimetin {
    background-color: blue !important;
}
.alimetin:hover {
    background-color: blueviolet;
}

#katman_2 {
    fill: #f39200;;
    stroke: black;
    stroke-width: 1px;
}
#katman_3 {
    fill: rgb(198, 198, 198);
    stroke: black;
    stroke-width: 1px !important;
}
#katman_4 {
    fill: rgb(0, 255, 0);
    stroke: black;
    stroke-width: 1px !important;
}
#katman_41 {
    fill: rgb(0, 255, 0);
    stroke: black;
    stroke-width: 2px !important;
}
.strokeWidth {
    stroke-width: 2px !important; 
}

#katman_4:hover {
    fill: blue;
}
#katman_41:hover {
    fill: blue;
}
#lineMain {
    fill: rgb(255, 123, 172);
    stroke: black;
    stroke-width: 1px;
}

#katman_4 {
    font-size: 6px;
}
#P01 {
    font-size: 6px;
    font-weight: 700;
    fill: white;
}
#P011 {
    font-size: 6px;
    font-weight: lighter;
    fill: white;
}
#P02 {
    font-size: 6px;
    font-weight: 700;
    fill: white;
}

.legantText {
    fill: black;
    font-size: 7px;
    font-weight: 100;
    font-style: normal;
}

#roadInnercolor {
    background-color: aqua;
}




