* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.Appp {
    margin-top: 20px;
    border-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100wh;
    margin-bottom: 20px;
}
.carousel {
    display: flex;
    width: 100%;
    height: 450px;
    max-width: 90%;
}
.carousel_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.carousel_card {
    display: flex;
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    opacity: 0;
    pointer-events: none;
    transform: scale(0.8);
    transition: 0.5s ease-in-out;
    -webkit-box-shadow: 6px 5px 13px 10px rgba(59,73,144,0.69); 
    box-shadow: 6px 5px 13px 10px rgba(59,73,144,0.69);
}
.carousel_card-active {
    opacity: 1;
    transform: scale(1);
    pointer-events: visible;
}

.card_image {
    width: 100%;
    object-fit: fill;
    
}
.card_overlay{
   position: absolute;
   width: 100%;
   height: 100%;
   background-color: rgba(59, 73, 144,0.3);
   display: flex;
   padding: 40px 30px;
   align-items: flex-end;
}
.card_title{
    color: white;
    font-size: 30px;
}
.carousel_arrow_left, 
.carousel_arrow_right {
    position: absolute;
    font-size: 40px;
    top: 50%;
    transform: translate(0,-50%);
    background-color: gainsboro;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding-bottom: 7px;
    cursor: pointer;
}
.carousel_arrow_left {
    left: 15px;
}
.carousel_arrow_right {
    right: 15px;
}
.carousel_paggination {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%,0);
}
.paggination_dot {
    height: 10px;
    width: 10px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}
.paggination_dot:hover {
    transform: scale(1.2);
}
.paggination_dot-active {
    background-color: blue;
}

@media screen and (max-width: 600px) {
    .carousel {
        display: flex;
        width: 77%;
        height: 268px;
        max-width: 80%;
    }
}
