

@media screen and (max-width: 600px) {
    .blogMain h4{
        font-size: 12px;
        margin-top: 74px !important;
    }
    .blogMain h5{
        font-size: 10px !important;
        margin: 6px 0px!important;

    }
    .blogMain p{
        font-size: 8px;
        margin: 2px 0px!important;
    }
    .blogMain i{
        margin-bottom: 2px !important;
    }
    .blogStack {
        margin-top: 80px !important;
        margin-top: 20px !important;
    }
}
