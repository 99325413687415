.contact_Main {
    margin-top: 110px;
}

@media screen and (max-width: 600px) {
    .contact_Main {
        margin-top: 92px !important;
        display: flex !important;
        justify-content: center !important;
    }
}