.contactMain {
    margin-right: 20px !important;
}
.mainGrid {
    display: block;
}
.mainGrid h5 {
    text-align: center;
    color: white;
}
.contactButton {
    background-color: brown;
    border: none;
    height: 25px;
    color: white;
    border-radius: 30px;
}
.contactButton:hover {
    background-color: #1888ff;
}

.contactForm {
    background-color: #3b4990;
    color: white;
    margin-bottom: 20px;
    padding: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: column !important;
    height: 500px;
    width: 400px !important;
}

@media screen and (max-width: 600px) {
    .contactForm {
        margin-bottom: 14px;
        padding: 8px;
        border-radius: 10px;
        height: 300px;
        width: 150px !important;
        font-size: 10px;
    }

    .contactButton {
        display: table;
        background-color: brown;
        border: none;
        height: 25px;
        color: white;
        border-radius: 30px;
    }
}