@media screen and (max-width: 600px) {
    h4{
        font-size: 14px !important;
        margin: 0px !important;
    }
    svg{
        font-size: 12px !important;
    }
    h6{
        font-size: 12px !important;
    }
    p{
        font-size: 10px !important;
    }
}