.parselWriting h5, p {
    color: rgb(59, 73, 144) !important;
}




@media only screen and (max-width: 600px) {
    .parselSvg {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
    .galaryList {
        padding-left: 0 !important;
    }
    .MuiTypography-h5 {
        font-size: 16px !important;
    }
    .MuiTypography-body1 {
        font-size: 10px !important;
        margin-top: 9px !important;
    }
    .MuiBox-root {
        width: 100% !important;
    }
    .MuiBox-root svg{
        width: 100% !important;
    }
    .parselContact {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}