.infoGeneral {
    width: 100%;
    height: 300px;
    background-color: rgb(59, 73, 144);
    margin-bottom: 1px;
}
.infoMain {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;
}
.infoMain a {
    text-decoration: none;
    color: white;
}
.infoMain a:hover {
    cursor: pointer;
    /* color: red; */
    background-color: #1888ff;
    /* text-decoration-line: underline; */
}
.infoItem {
    width: 20%;
    height: 20vh;
    margin-right: 30px;
}
.infoItem h5,p {
    color: white !important;
}

.footerMain {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0);
}
.footer {
    width: 100%;
    height: 200px;
    background-color: rgb(59, 73, 144);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    color: white;
}
.socialMedia svg {
    color: white;
    margin: 20px;
    font-size: 50px;
    cursor: pointer;
}

.blogMain a {
    font-weight: 700;
    text-decoration: none;
    color :rgb(59, 73, 144);
}
.blogMain a:hover {
    cursor: pointer;
    color: red;
    text-decoration-line: underline;
}
.tradeMarke {
    color: white !important;
}
@media screen and (max-width: 600px) {
    .infoGeneral {
        width: 100%;
        height: 86vh !important;
    }
    .infoMain {
        display: flex;
        flex-direction: column !important;
        width: 100%;
        height: 20vh;
        justify-content: start;
        align-items: start;
        
    }
    .infoMain a {
        text-decoration: none;
        color: white;
        font-size: 9px;
        padding: 0;
    }
    .infoMain scg {
        padding: 0;
    }

    .infoItem {
        font-size: 9px;
        width: 100%;
        height: 27vh;
    }
    .infoMain h5{
        font-size: 14px;
        width: 100%;
        
    }
    .infoItemH5 {
        margin-top: 12px !important;
    }
    .socialMedia svg {
        color: white;
        margin: 10px;
        font-size: 25px;
    }
    .footer {
        width: 100%;
        height: 100px;
    }
    .footer p{
        font-size: 10px;
    }
  }